<template>
    <section id="wrapper">
        <!--        Header -->

        <section id="header">
            <div
                    id="myCarousel"
                    class="carousel slide carousel-fade"
                    data-bs-ride="carousel"
            >
                <div class="carousel-indicators">
                    <button
                            type="button"
                            data-bs-target="#myCarousel"
                            data-bs-slide-to="0"
                            class="active"
                            aria-current="true"
                            aria-label="Slide 1"
                    ></button>
                    <button
                            type="button"
                            data-bs-target="#myCarousel"
                            data-bs-slide-to="1"
                            aria-label="Slide 2"
                    ></button>
                    <button
                            type="button"
                            data-bs-target="#myCarousel"
                            data-bs-slide-to="2"
                            aria-label="Slide 3"
                    ></button>
                    <button
                            type="button"
                            data-bs-target="#myCarousel"
                            data-bs-slide-to="3"
                            aria-label="Slide 4"
                    ></button>
                    <button
                            type="button"
                            data-bs-target="#myCarousel"
                            data-bs-slide-to="4"
                            aria-label="Slide 5"
                    ></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img
                                src="../assets/images/header/6.jpg"
                                class="d-block w-100"
                                alt="..."
                        />
                        <div class="carousel-caption d-none d-md-block">
                            <div class="content">
                                <h1>Shree Balaji Polytech Products</h1>
                                <p>
                                    Manufacturers & Exporters of Rubber Gaskets, Sealing Rings &
                                    Customised Rubber Solutions for Water Pipelines (DI Pipes,
                                    Fittings & Valves, CI Pipes, Fittings & Valves, HDPE-DWC
                                    Pipes, RCC Pipes)
                                </p>

                                <br/>
                                <router-link class="btn btn-white me-3" to="/about"
                                >About Us
                                </router-link>

                                <router-link class="btn btn-theme" to="/products"
                                >Our Products
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img
                                src="../assets/images/header/5.jpg"
                                class="d-block w-100"
                                alt="..."
                        />
                        <div class="carousel-caption d-none d-md-block">
                            <div class="content">
                                <h1>Tyton Gaskets</h1>
                                <p>
                                    WRAS Approved Tyton Gaskets from 80 mm to 1500 mm for Tyton
                                    Socket Push-On Joints of DI Pipes and Fittings
                                </p>

                                <br/>
                                <router-link class="btn btn-white me-3" to="/"
                                >Our Products
                                </router-link>

                                <router-link class="btn btn-theme" to="/"
                                >Contact Us
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img
                                src="../assets/images/header/3.jpg"
                                class="d-block w-100"
                                alt="..."
                        />
                        <div class="carousel-caption d-none d-md-block">
                            <div class="content">
                                <h1>WRAS Approved Gaskets</h1>
                                <p>
                                    WRAS Approved EPDM Gaskets (Tyton Gaskets, MJ Gaskets, Flange
                                    Gaskets) suitable for drinking water and sewage pipelines (DI
                                    Pipes, CI Pipes, DI Fittings, CI Fittings, HDPE DWC Pipes &
                                    Fittings and more)
                                </p>

                                <br/>
                                <router-link class="btn btn-white me-3" to="/"
                                >Our Products
                                </router-link>

                                <router-link class="btn btn-theme" to="/"
                                >Our Products
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img
                                src="../assets/images/header/4.jpg"
                                class="d-block w-100"
                                alt="..."
                        />
                        <div class="carousel-caption d-none d-md-block">
                            <div class="content">
                                <h1>Extruded Rubber Profiles</h1>
                                <p>
                                    Continuous Length Profiles (EPDM, SBR, NBR, Neoprene) for Door
                                    Seals, DI manhole Covers, and other infrastructural
                                    applications including Sewage and Drainage Systems
                                </p>

                                <br/>
                                <router-link class="btn btn-white me-3" to="/"
                                >Our Products
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img
                                src="../assets/images/header/1.jpg"
                                class="d-block w-100"
                                alt="..."
                        />
                        <div class="carousel-caption d-none d-md-block">
                            <div class="content">
                                <h1>Manufacturers & Exporters of Rubber Products</h1>
                                <p>
                                    Load Bearing Rubber Products Manhole Cover Gaskets, Grooved
                                    Rubber Sole Plates, Marine Application Gaskets among others
                                </p>

                                <br/>
                                <router-link class="btn btn-white me-3" to="/"
                                >Our Products
                                </router-link>

                                <router-link class="btn btn-theme" to="/"
                                >Our Products
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <button
                        class="carousel-control-prev"
                        type="button"
                        data-bs-target="#myCarousel"
                        data-bs-slide="prev"
                >
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button
                        class="carousel-control-next"
                        type="button"
                        data-bs-target="#myCarousel"
                        data-bs-slide="next"
                >
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </section>

        <!--        About The Company-->
        <section class="container">
            <br/><br/>
            <div class="row mt-5">
                <div class="col-lg-7 text-center">
                    <h1>Rubber Solutions for Various Industrial Requirements</h1>
                    <div class="row mt-5">
                        <div class="col-md-5 fw-bold">
                            <p>
                                We manufacture rubber gaskets, sealing rings and solutions for
                                water pipeline, sewage and drainage infrastructure and water
                                infrastructure. We are an ISO 9001:2015 Organisation based out
                                of Kolkata, India
                            </p>
                        </div>
                        <div class="col-md-6 text-start">
                            <p>
                                Gaskets in any piping system lock two pipes together to control
                                leakage of fluid (water, sewage etc) in/out of the system. A
                                robust piping mechanism needs sturdy and chemically strong
                                rubber gaskets to avoid
                            </p>
                            <ul>
                                <li>
                                    Attack of underground bacteria and microbes to contaminate
                                    drinking water
                                </li>
                                <li>Underground fluid leakage through poor jointing</li>
                                <li>
                                    Dismantling of system as gaskets succumb to continous erosion
                                    through water / sewage
                                </li>
                                <li>Growth of microorganisms in drinking water</li>
                            </ul>
                            <p>
                                Rigorous SCM, stringent quality checks at every stage of process
                                flow & strong teamwork helps SBPPL to produce the best fit
                                gaskets to avoid the aforesaid problems
                            </p>

                            <router-link class="btn btn-black mt-1" to="/about"
                            >More About Us
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 align-self-center">
                    <div class="row">
                        <div class="col-7 align-self-center">
                            <img src="@/assets/images/home/factory.jpg" class="img-fluid"/>
                        </div>
                        <div class="col-4 align-self-end">
                            <img src="@/assets/images/home/factory.jpg" class="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!--        Work Highlights -->
        <section class="container" id="highlights">
            <div class="text-center highlight">
                <font-awesome-icon :icon="['fab', 'envira']" size="8x"/>
                <h4>Reliable</h4>
            </div>

            <div class="text-center highlight">
                <font-awesome-icon :icon="['fab', 'medium']" size="8x"/>
                <h4>Sturdy</h4>
            </div>

            <div class="text-center highlight">
                <font-awesome-icon :icon="['fab', 'medium']" size="8x"/>
                <h4>Satisfaction Guarantee</h4>
            </div>

            <div class="text-center highlight">
                <font-awesome-icon :icon="['fab', 'medium']" size="8x"/>
                <h4>Quality Product</h4>
            </div>

            <div class="text-center highlight">
                <font-awesome-icon :icon="['fab', 'medium']" size="8x"/>
                <h4>Export Quality</h4>
            </div>
        </section>

        <!--        Our Services-->
        <section id="services">
            <div class="container">
                <h5 class="color-theme">The Best Service You Deserve</h5>
                <h1 class="m-auto mt-4">
                    High Performance Services for Multiple Industrial Use
                </h1>
                <br/><br/>
                <div class="row mt-5">
                    <div class="col-md-4">
                        <div class="service">
                            <img src="@/assets/images/icons/factory.png"/>
                            <h2 class="mt-5">Manufacturer</h2>
                            <p class="mt-3 color-light">
                                With a facility of more than 6000 sq. ft., over 15 production
                                and 20 testing equipments, we have separate sections for each
                                process & sub-process, right from storage to despatch. Our
                                annual capacity exceeds 350 thousand metric tonnes, with a 24x6
                                working cycle.
                            </p>
                            <hr/>
                            <div class="d-flex align-items-center justify-content-between">
                                <label class="fw-bold">Read More</label>
                                <i class="fas fa-arrow-right"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="service">
                            <img src="@/assets/images/icons/crane.png"/>
                            <h2 class="mt-5">Exporter</h2>
                            <p class="mt-3 color-light">
                                Since 2012, our exports have exceeded 30% of our annual volumes.
                                Our gaskets have endured the heat of UAE (Dubai & Iran) as well
                                as the chill of Europe (Russia & Finland). Our highly
                                experienced team ensures precision & durability, coherent with
                                international demand & standards.
                            </p>
                            <hr/>
                            <div class="d-flex align-items-center justify-content-between">
                                <label class="fw-bold">Read More</label>
                                <i class="fas fa-arrow-right"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="service">
                            <img src="@/assets/images/icons/tank-truck.png"/>
                            <h2 class="mt-5">Innovator</h2>
                            <p class="mt-3 color-light">
                                In a cutting edge market, we supply rubber sheets, rubber
                                compounds & other extruded products to other manufacturers in
                                our field. Having a loyal customer base, &, we believe in
                                lending a co-operative edge more than a competitive one to our
                                fellow-industrialists.
                            </p>
                            <hr/>
                            <div class="d-flex align-items-center justify-content-between">
                                <label class="fw-bold">Read More</label>
                                <i class="fas fa-arrow-right"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!--        How We Work-->
        <section id="how-we-work">
            <div class="section-background"></div>
            <div class="container">
                <div class="row">
                    <div class="col-md-7">
                        <div class="content pb-5">
                            <label>How We Work</label>
                            <h1>Get Premium <strong>Industrial Services</strong></h1>
                            <div class="underline_highlight"></div>
                            <p class="mt-4">
                                With over 15 years of expertise, we offer tremendous value for
                                our buyers through our<br/><br/>
                                - technical and commercial knowledge gained as part of the water
                                infrastructure industry,<br/>
                                - supply chain efficiencies through faster order processing and
                                continuous production,<br/>
                                - stringent quality checks before shipping,<br/>
                                - customised solutions tailored for your various requirements.
                            </p>
                            <hr class="mt-5"/>
                            <div class="row mt-5">
                                <div class="col-md-6">
                                    <div class="d-flex">
                                        <!-- Image -->
                                        <img
                                                class="icon"
                                                src="@/assets/images/icons/customise.png"
                                        />
                                        <!-- Body -->
                                        <div class="ms-3 me-3">
                                            <h5 class="fw-bold">Customised Range</h5>
                                            <p class="mt-3">
                                                Products made to drawings and specifications
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="d-flex">
                                        <!-- Image -->
                                        <img
                                                class="icon"
                                                src="@/assets/images/icons/supply_chain.png"
                                        />
                                        <!-- Body -->
                                        <div class="ms-3 me-3">
                                            <h5 class="fw-bold">Vetted Suppliers</h5>
                                            <p class="mt-3">
                                                Authorised & Trustworthy vendors and raw materials
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-5 mt-4">
                                <div class="col-md-6">
                                    <div class="d-flex">
                                        <!-- Image -->
                                        <img
                                                class="icon"
                                                src="@/assets/images/icons/heavy_machinery.png"
                                        />
                                        <!-- Body -->
                                        <div class="ms-3 me-3">
                                            <h5 class="fw-bold">Sound Technology</h5>
                                            <p class="mt-3">
                                                Advanced heavy machinery and equipments used
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="d-flex">
                                        <!-- Image -->
                                        <img class="icon" src="@/assets/images/icons/quality.png"/>
                                        <!-- Body -->
                                        <div class="ms-3 me-3">
                                            <h5 class="fw-bold">Quality Procedures</h5>
                                            <p class="mt-3">
                                                Quality test conforming to internation industrial
                                                parameters
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-5 position-relative">
                        <img
                                src="@/assets/images/home/worker-executive.jpg"
                                class="large-image"
                        />
                        <div class="image-overlay-content">
                            <h5>Dedicated Team</h5>
                            <p>
                                Shree Balaji Polytech has more than 15+ yrs of experience in
                                handling challenging orders and providing quality services
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!--        Top Products-->
        <section id="top-products" v-show="this.products.length">
            <div class="container content">
                <h1>Popular <strong>Products Manufactured</strong> by Us</h1>
                <section class="product-area mt-5">
                    <SingleProduct
                            v-for="item in products"
                            :key="item.name"
                            :product="item"
                    />
                </section>
            </div>
        </section>

        <!--        Clientele-->
        <section id="clientele">
            <div class="image">
                <img src="@/assets/images/home/factory2.jpg" class="img-fluid"/>
            </div>
            <div class="content">
                <label>More About Us</label>
                <h1>
                    Client
                    <strong>Testimonials</strong>
                </h1>
                <div class="underline_highlight"></div>
                <p class="mt-4">
                    <i>'Pioneers in their field who understand the value of customers. Hence always deliver quality products in a. Timely manner. Highly appreciated.'</i>
                </p>

                <p class="mt-4">
                    <i>'They don't compromise with quality of products and there services'</i>
                </p>

                <p class="mt-4">
                    <i>'The Director and staff are very welcoming and keep to their commitments.'</i>
                </p>

                <p class="mt-4">
                    <i>'Good export house for Gaskets.....'</i>
                </p>

                <p class="mt-4">
                    <i>'Really great behaviour. Thanks Vishal ji.'</i>
                </p>

                <p class="mt-4">
                    <i>'Good quality and price competitive gaskets for DI pipes'</i>
                </p>
                <hr class="mt-5"/>

                <div class="d-flex justify-content-between mt-5">
                    <div>
                        <h1 class="color-theme">100+</h1>
                        <label class="color-light">Product Range</label>
                    </div>

                    <div>
                        <h1 class="color-theme">1000+</h1>
                        <label class="color-light">Satisfied Clients</label>
                    </div>

                    <div>
                        <h1 class="color-theme">5000+</h1>
                        <label class="color-light">Orders Completed</label>
                    </div>
                </div>
            </div>
        </section>

        <!--        Blogs-->
        <section id="blogs">
            <div class="container">
                <h3>Latest News: <strong>Rubber Manufacturing & Products</strong></h3>
                <p>
                    Stay up to date with our latest news, event schedules and corporate
                    developments for new blogs
                </p>
                <div class="row mt-5">
                    <div class="col-md-4">
                        <div class="blog">
                            <div class="thumbnail">
                                <img src="https://img.etimg.com/thumb/msid-83514550,width-210,imgsize-67139,,resizemode-4,quality-100/rubber-sap-istock.jpg"/>
                            </div>
                            <div class="content">
                                <label>Rubber futures test Rs 17,000 mark.</label>
                                <div
                                        class="d-flex align-items-baseline mt-2"
                                        style="font-size: 0.8rem; color: #777"
                                >
                                    <i class="fa fa-calendar-alt"></i>
                                    <span class="ms-1">June 15, 2021</span>
                                </div>
                                <hr/>
                                <p class="mt-4">
                                    Domestic rubber prices moved in a tight range with a negative bias amid thin trade.
                                    On MCX NSE 0.63%, rubber futures for June 30 delivery settled at Rs 17,190 per 100kg.
                                    The coronavirus pandemic continues to sway the overall market sentiment in the natural rubber market,say analysts.
                                </p>
                                <div
                                        class="
                    d-flex
                    align-items-center
                    justify-content-between
                    mt-3
                    color-theme
                  "
                                >
                                    <a target="_blank"
                                       href="https://economictimes.indiatimes.com/markets/commodities/news/rubber-futures-test-rs-17000-mark-time-to-take-positions/articleshow/83514571.cms">Read
                                        More</a>
                                    <font-awesome-icon :icon="['fa','arrow-right']"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="blog">
                            <div class="thumbnail">
                                <img src="@/assets/images/home/blog3.jpg"/>
                            </div>
                            <div class="content">
                                <label>Rubber, Chem & Petrochem Skill Development Council appoints Chairman</label>
                                <div
                                        class="d-flex align-items-baseline mt-2"
                                        style="font-size: 0.8rem; color: #777"
                                >
                                    <i class="fa fa-calendar-alt"></i>
                                    <span class="ms-1">September 20, 2021</span>
                                </div>
                                <hr/>
                                <p class="mt-4">
                                    To ensure availability of skilled human resources to rubber industry, RCPSDC, a
                                    sector skill council for the rubber and chemical sector was set up by All India
                                    Rubber Industries Association (AIRIA) ..
                                </p>
                                <div
                                        class="
                    d-flex
                    align-items-center
                    justify-content-between
                    mt-3
                    color-theme
                  "
                                >
                                    <a target="_blank"
                                       href="https://economictimes.indiatimes.com/industry/auto/tyres/rubber-chem-petrochem-skill-development-council-appoints-chairman/articleshow/86362201.cms">Read
                                        More</a>
                                    <font-awesome-icon :icon="['fa','arrow-right']"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="blog">
                            <div class="thumbnail">
                                <img src="@/assets/images/home/blog2.jpg"/>
                            </div>
                            <div class="content">
                                <label>Rubber Board to launch virtual trade fair on Sept 30</label>
                                <div
                                        class="d-flex align-items-baseline mt-2"
                                        style="font-size: 0.8rem; color: #777"
                                >
                                    <i class="fa fa-calendar-alt"></i>
                                    <span class="ms-1">September 28, 2021</span>
                                </div>
                                <hr/>
                                <p class="mt-4">
                                    Rubber Board will be hosting a virtual trade fair on September 30 aimed at
                                    showcasing Indian rubber products especially from the MSME sector in overseas
                                    markets.
                                    The trade fair assumes greater significance at a time when all sectors are working
                                    hard to achieve the export target of $400
                                </p>
                                <div
                                        class="
                    d-flex
                    align-items-center
                    justify-content-between
                    mt-3
                    color-theme
                  "
                                >
                                    <a target="_blank"
                                       href="https://www.thehindubusinessline.com/economy/agri-business/rubber-board-to-launch-virtual-trade-fair-on-sept-30/article36698922.ece">Read
                                        More</a>
                                   <font-awesome-icon :icon="['fa','arrow-right']"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
    import {Carousel} from "bootstrap";
    import SingleProduct from "../components/SingleProduct";

    export default {
        name: "Home",

        components: {
            SingleProduct,
        },

        props: {
            username: String,
        },
        data() {
            return {
                images: {
                    signature: require("@/assets/images/signature.png"),
                },
                products: [],
            };
        },
        methods: {
            loadCarousel() {
                const elm = this.$refs["carousel"];
                new Carousel(elm);
            },
            ChangeUrl() {
                this.$router.push("about");
            },
            newStyle(num) {
                return {
                    // backgroundImage: "linear-gradient(to bottom,rgba(0,0,0,0.4), rgba(0,0,0,0.4)),url('" + this.images.header[num] + "')",
                    backgroundImage:
                        "linear-gradient(to bottom,rgba(0,0,0,0.4), rgba(0,0,0,0.4)),url('" +
                        require("@/assets/images/header/" + num + ".jpg") +
                        "')",
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                };
            },

            getTopProducts() {
                const that = this;
                const all_products = this.$store.state.products;
                all_products.forEach((type) => {
                    const products = type.products;
                    products.forEach((product) => {
                        if (product.top) that.products.push(product);
                    });
                });
                console.log("Products ", this.products);
            },
        },
        created() {
            this.loadCarousel();
            this.getTopProducts();
        },
        computed: {},
    };
</script>

<style lang="scss">
    #header {
        background: black;
    }

    #myCarousel {
        .carousel-inner,
        .carousel-item {
            height: 100vh;

            @media (max-width: 768px) {
                height: 70vh;
            }

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        .carousel-caption {
            top: 0;
            left: 0;
            color: #fff;
            text-align: center;
            height: 100vh;
            width: 100%;
            display: flex !important;
            align-items: center;
            padding: 0;
            bottom: 0;
            background: linear-gradient(
                            to right,
                            rgba(0, 0, 0, 0.6),
                            rgba(0, 0, 0, 0.6)
            );

            .content {
                color: white;
                text-align: left;
                padding-left: 8rem;

                h1 {
                    font-size: 4rem;
                    width: 60%;
                }

                p {
                    width: 50%;
                    margin-top: 1.2rem;
                }

                @media (min-width: 1400px) {
                    padding-left: 12rem;
                    h1 {
                        font-size: 5rem;
                    }
                }

                @media (max-width: 768px) {
                    padding-left: 4rem;
                    h1 {
                        width: 80%;
                        font-size: 1.5rem;
                    }
                    p {
                        width: 80%;
                        font-size: 0.85rem;
                    }
                }
            }

            @media (max-width: 768px) {
                height: 70vh;
                align-items: center;
                padding-top: 3rem;
            }
        }
    }

    #highlights {
        margin: 6rem auto;
        display: flex;

        @media (max-width: 768px) {
            flex-wrap: wrap;
        }
    }

    #highlights .highlight {
        padding: 0.5rem;
        flex-basis: 0;
        flex-grow: 1;
        text-align: center;
    }

    #highlights .highlight h4 {
        margin-top: 1rem;
        width: 75%;
        margin-left: auto;
        margin-right: auto;
    }

    #services {
        text-align: center;
        background: #f9f9f9;
        padding-top: 4rem;
        padding-bottom: 4rem;

        h1 {
            width: 50%;

            @media (max-width: 768px) {
                width: 100%;
            }
        }
    }

    #services .service {
        text-align: left;
        padding: 4rem;
        background: white;
        box-shadow: 0 0 8px #888a;
        transition: all ease-in-out 300ms;
        @media (max-width: 768px) {
            padding: 1rem;
        }
    }

    #services .service img {
        width: 4rem;
    }

    .service img,
    .service h2,
    .service p {
        transition: all ease-in-out 100ms;
    }

    .service:hover {
        background: #f87128 !important;
        cursor: pointer;
        color: white !important;
        margin-top: -0.5rem;
    }

    .service:hover img {
        filter: invert(100%);
    }

    .service:hover h2,
    .service:hover p {
        color: white;
    }

    #how-we-work {
        margin-top: 4rem;
        position: relative;
        color: white;

        .section-background {
            position: absolute;
            width: 100%;
            height: 85%;
            background: #020d26;
            z-index: -1;
            margin-top: 4rem;
        }

        .content {
            margin-top: 8rem;
        }

        .large-image {
            display: block;
            margin: 0 auto;
            height: 100%;
            width: 95%;
            object-fit: cover;
        }

        p {
            color: #aaa;
            font-size: 0.9rem;
        }

        img.icon {
            width: 60px;
            height: 60px;
            filter: invert(100%);
        }

        .image-overlay-content {
            background: rgba(248, 113, 40, 0.87) !important;
            padding: 2rem;
            position: absolute;
            top: 60%;
            left: 5%;
            width: 80%;

            p {
                color: white !important;
                font-weight: 300;
            }

            @media (max-width: 768px) {
                position: relative;
                top: 0;
                left: 0;
                width: 100%;
                background: rgba(248, 113, 40, 1) !important;
            }
        }

        @media (max-width: 768px) {
            margin-top: 0;
            .section-background {
                margin-top: 0;
            }
            .content {
                margin-top: 4rem;
            }

            .large-image {
                height: auto;
                width: 100%;
            }
        }
    }

    #top-products {
        padding: 6rem 0;
        text-align: center;
    }

    #clientele {
        display: flex;
        margin-top: 4rem;

        div.image {
            width: 45%;

            img {
                width: 100%;
            }
        }

        div.content {
            width: 55%;
            background: #020d26;
            padding: 4rem;
            margin-top: 4rem;
            color: white;
        }

        @media (max-width: 768px) {
            flex-wrap: wrap;
            margin-top: 0rem;
            div.image {
                width: 100%;
            }
            div.content {
                width: 100%;
                margin-top: 0rem;
                padding: 2rem;
            }
        }
    }

    #blogs {
        padding: 6rem 0;
        background: $section-background;
    }

    .blog {
        box-shadow: 0 0 16px #0002;
        cursor: pointer;

        .thumbnail {
            overflow: hidden;
        }

        img {
            width: 100%;
            height: 240px;
            object-fit: cover;
            transition: all ease-in-out 500ms;
        }

        a {
            color: $theme-color !important;
            &:hover{
                opacity: 0.8;
            }
        }

        &:hover {
            img {
                transform: scale(1.2);
            }
        }

        .content {
            padding: 1.5rem 1rem;

            label {
                font-size: 1.4rem;
            }

            p {
                font-size: 0.9rem;
            }
        }
    }
</style>
